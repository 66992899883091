import styled from 'styled-components';
export { FieldError } from 'styles/components/forms';

export const MainContainer = styled.span`
  position: relative;
  input {
    position: absolute;
    left: -200vw;
  }
`;
