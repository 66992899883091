import styled, { css } from 'styled-components';
import { BasePanel } from 'styles/components';
import { Colors } from 'styles/constants';
import { BasePaginatorHeader, BasePaginatorListItem } from 'styles/components';
export {
  FormRow,
  Button,
  ActionButton,
  ActivityIndicator,
  FormActions,
  TrashIcon,
  PaginatorColumn as Column,
  PaginatorActionsColumn as ActionsColumn,
} from 'styles/components';

export const Panel = styled(BasePanel)`
  flex: 0 1 100%;
  flex-direction: column;
  background: ${Colors.White};
  padding: 24px;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
`;

const baseGridCss = css`
  grid-template-columns: 160px 300px 200px 200px auto;
`;

export const ListHeader = styled(BasePaginatorHeader)`
  ${baseGridCss}
`;

export const ListItem = styled(BasePaginatorListItem)`
  ${baseGridCss}
`;

export const Error = styled.span`
  color: ${Colors.Magenta};
`;
